// main.js - PERBAIKAN
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import VueLazyload from 'vue-lazyload'
import AOS from 'aos'

// Import language files
import en from './locales/en.json'
import id from './locales/id.json'

// PERBAIKAN: Memindahkan inisialisasi tracking ke fungsi yang dipanggil dengan delay
const initTracking = () => {
    // Google Tag Manager
    try {
        if (!document.querySelector('script[src*="googletagmanager.com/gtm.js"]')) {
            const gtmScript = document.createElement('script');
            gtmScript.async = true; // PERBAIKAN: Menambahkan async
            gtmScript.innerHTML = `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-5RTMDNZ9');
            `;
            document.head.appendChild(gtmScript);
            
            if (!document.querySelector('iframe[src*="googletagmanager.com/ns.html"]')) {
                const noscript = document.createElement('noscript');
                const iframe = document.createElement('iframe');
                iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-5RTMDNZ9';
                iframe.height = '0';
                iframe.width = '0';
                iframe.style.display = 'none';
                iframe.style.visibility = 'hidden';
                noscript.appendChild(iframe);
                document.body.insertBefore(noscript, document.body.firstChild);
            }
        }
        
        // Initialize dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            'event': 'gtm.js'
        });
    } catch (error) {
        console.error('Error initializing GTM:', error);
    }
    /* eslint-enable no-undef */
};

// PERBAIKAN: Simplifikasi helper functions untuk events
/* eslint-disable no-undef */
window.sendMetaPixelEvent = (eventName, params = {}) => {
  if (window.fbq) {
    window.fbq('track', eventName, params);
  }
};

window.sendGTMEvent = (eventName, params) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...params
    });
  }
};
/* eslint-enable no-undef */

// PERBAIKAN: Simplifikasi performance monitoring
const performanceMonitor = {
    startTimes: {},
    metrics: {},
    
    start(label) {
        this.startTimes[label] = performance.now()
    },
    
    end(label) {
        if (this.startTimes[label]) {
            this.metrics[label] = performance.now() - this.startTimes[label]
            delete this.startTimes[label]
        }
    }
};

// PERBAIKAN: Simplifikasi lazy load options
const lazyloadOptions = {
    preLoad: 1.5,
    error: '/assets/img/business-4/error.avif',
    loading: '/assets/img/business-4/loading_low.avif',
    attempt: 2,
    observer: true,
    filter: {
        // PERBAIKAN: Simplifikasi processing gambar
        progressive(listener) {
            const isMobile = window.innerWidth < 768;
            const size = isMobile ? 'sm' : 'md';
            
            // Transformasi URL gambar
            if (listener.src.includes('.avif') || listener.src.includes('.webp')) {
                listener.src = listener.src.replace(/\.(avif|webp)$/, `_${size}.$1`);
            }
        }
    },
    adapter: {
        loaded({ el }) {
            el.classList.add('loaded');
        },
        error({ el }) {
            el.classList.add('error');
            
            // Fallback format jika diperlukan
            if (el.src.includes('.avif')) {
                el.src = el.src.replace('.avif', '.webp');
            } else if (el.src.includes('.webp')) {
                el.src = el.src.replace('.webp', '.jpg');
            }
        }
    }
};

// Create i18n instance
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('language') || 'en',
    fallbackLocale: 'id',
    messages: { en, id },
    missingWarn: false,
    fallbackWarn: false
});

// Initialize Vue app
const app = createApp(App);

// Register plugins
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueLazyload, lazyloadOptions);

// PERBAIKAN: Simplifikasi WhatsApp tracking
const initContactTracking = () => {
    /* eslint-disable no-undef */
    window.whatsAppTracker = {
        setupTracking() {
            // Deteksi kembalinya pengguna dari WhatsApp
            window.addEventListener('focus', this.checkWhatsAppEngagement);
            
            // PERBAIKAN: Menggunakan hanya satu interval dengan waktu lebih lama
            this.trackingInterval = setInterval(() => {
                this.checkAbandonedSessions();
                this.checkWhatsAppEngagement();
            }, 30000); // Periksa setiap 30 detik (lebih hemat resource)
            
            console.log('WhatsApp Tracker initialized');
        },
        
        // Fungsi-fungsi lain tetap sama
        checkWhatsAppEngagement() {
            const wasClicked = localStorage.getItem('wa_clicked') === 'true';
            if (!wasClicked) return;
            
            const clickTime = parseInt(localStorage.getItem('wa_click_time') || '0');
            const currentTime = new Date().getTime();
            const secondsElapsed = Math.floor((currentTime - clickTime) / 1000);
            const clickSource = localStorage.getItem('wa_click_source') || 'unknown';
            const phoneNumber = localStorage.getItem('wa_phone_number') || '6281333493448';
            
            // Jangan laporkan lagi jika sudah terlalu lama
            if (secondsElapsed > 1800) { // 30 menit
                localStorage.removeItem('wa_clicked');
                localStorage.removeItem('wa_click_time');
                localStorage.removeItem('wa_click_source');
                localStorage.removeItem('wa_phone_number');
                return;
            }
            
            // Analisis berdasarkan waktu
            if (secondsElapsed < 10) {
                // Kemungkinan tidak membuka WhatsApp
                window.sendGTMEvent('whatsapp_abandoned', {
                    elapsed_time: secondsElapsed,
                    whatsapp_source: clickSource,
                    phone_number: phoneNumber
                });
            } 
            else if (secondsElapsed >= 10 && secondsElapsed < 30) {
                // Kemungkinan membuka WhatsApp tapi belum berinteraksi
                window.sendGTMEvent('whatsapp_opened', {
                    elapsed_time: secondsElapsed,
                    whatsapp_source: clickSource,
                    phone_number: phoneNumber
                });
            }
            else if (secondsElapsed >= 30 && secondsElapsed < 300) {
                // Kemungkinan berinteraksi dengan WhatsApp
                window.sendGTMEvent('whatsapp_engagement', {
                    elapsed_time: secondsElapsed,
                    engagement_level: secondsElapsed > 60 ? 'high' : 'medium',
                    whatsapp_source: clickSource,
                    phone_number: phoneNumber
                });
                
                // Bersihkan status setelah melaporkan engagement
                localStorage.removeItem('wa_clicked');
                localStorage.removeItem('wa_click_time');
                localStorage.removeItem('wa_click_source');
                localStorage.removeItem('wa_phone_number');
            }
        },
        
        checkAbandonedSessions() {
            const wasClicked = localStorage.getItem('wa_clicked') === 'true';
            if (!wasClicked) return;
            
            const clickTime = parseInt(localStorage.getItem('wa_click_time') || '0');
            const currentTime = new Date().getTime();
            const minutesElapsed = Math.floor((currentTime - clickTime) / (1000 * 60));
            
            // Jika lebih dari 30 menit dan masih ada data, anggap sebagai sesi yang ditinggalkan
            if (minutesElapsed > 30) {
                const clickSource = localStorage.getItem('wa_click_source') || 'unknown';
                const phoneNumber = localStorage.getItem('wa_phone_number') || '6281333493448';
                
                window.sendGTMEvent('whatsapp_session_expired', {
                    elapsed_minutes: minutesElapsed,
                    whatsapp_source: clickSource,
                    phone_number: phoneNumber
                });
                
                // Bersihkan status
                localStorage.removeItem('wa_clicked');
                localStorage.removeItem('wa_click_time');
                localStorage.removeItem('wa_click_source');
                localStorage.removeItem('wa_phone_number');
            }
        },
        
        cleanup() {
            window.removeEventListener('focus', this.checkWhatsAppEngagement);
            clearInterval(this.trackingInterval);
        }
    };
    /* eslint-enable no-undef */
    
    // Fungsi untuk bind metode ke this
    window.whatsAppTracker.checkWhatsAppEngagement = window.whatsAppTracker.checkWhatsAppEngagement.bind(window.whatsAppTracker);
    window.whatsAppTracker.checkAbandonedSessions = window.whatsAppTracker.checkAbandonedSessions.bind(window.whatsAppTracker);
    
    // Inisialisasi tracking WhatsApp
    window.whatsAppTracker.setupTracking();
};

// Router navigation guards
router.beforeEach((to, from, next) => {
    // Reset scroll position
    if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
    }
    
    next();
});

// PERBAIKAN: Delay inisialisasi AOS
let isAOSInitialized = false;
router.afterEach((to) => {
    /* eslint-disable no-undef */
    // Track page view dengan Meta Pixel untuk SPA
    if (window.fbq) {
        window.fbq('track', 'PageView', {
            page_title: to.name || 'Unknown Page',
            page_path: to.path
        });
    }
    /* eslint-enable no-undef */
    
    // PERBAIKAN: Initialize AOS dengan delay
    if (!isAOSInitialized && typeof window !== 'undefined') {
        // Delay AOS initiation until after important elements are loaded
        setTimeout(() => {
            AOS.init({
                duration: 400,
                easing: 'ease-in-out',
                once: true,
                offset: 50,
                delay: 0,
                disable: window.innerWidth < 768,
                mirror: false,
                anchorPlacement: 'top-bottom',
            });
            isAOSInitialized = true;
        }, 1000);
    }
});

// PERBAIKAN: Simplifikasi service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
            .catch(err => {
                console.error('ServiceWorker registration failed:', err);
            });
    });
}

// Error handling
app.config.errorHandler = (err) => {
    console.error('Vue Error:', err);
};

// Mount app
performanceMonitor.start('appMount');
app.mount('#app');
performanceMonitor.end('appMount');

// PERBAIKAN: Delay loading tracking scripts
window.addEventListener('load', () => {
    // Delay inisialisasi tracking script untuk memprioritaskan konten
    setTimeout(() => {
        initTracking();
        initContactTracking();
    }, 3000); // Delay 3 detik
});

// Export for testing
export { app, router, store, i18n };